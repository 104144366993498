/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql, Link } from 'gatsby';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/BannerSection';
import PageContent from '../page-components/page-content';

// Assets
import BANNER_IMG from '../images/about-us-page/bannerAbout.jpg';
import ABOUT_IMG from '../images/about-us-page/about.png';

// Style
import '../page-styles/about-us.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function AboutUsPage() {
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Qui sommes nous" />
      <Container className="page-about fluid">
        <BannerSection title="Qui sommes nous" bannerImage={BANNER_IMG} />
        <Container className="about-us-page fluid" fluid>
          <Container className="Content-container">
            <div className="links-container">
              <Container className="top flex items-center fluid">
                <Link className="breadcrumb__link" to="/">
                  Accueil
                </Link>
                <span className="m-r-l">/</span>
                <Link className="breadcrumb__link" to="/about-us/">
                  Qui sommes nous
                </Link>
              </Container>
            </div>

            <PageContent
              healthImag={ABOUT_IMG}
              aboutContent1="L’Unité de Gestion Par Objectifs chargée du suivi du neuvième
programme-cadre européen pour la Recherche &amp; Innovation
«Horizon Europe» (UGPO-HE) a été créée au sein du Ministère de
l’Enseignement Supérieur et de la Recherche Scientifique [MESRS]
le 23 janvier 2023 en vertu d’un décret gouvernemental (n° 2023-13)."
            />
          </Container>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AboutUsPage;
